<template>
  <div>
    <btc-nav-bar
      :items="types"
      @handle-navbar="handleType" @click.native="handleCloseSelect"/>
    <div class="item-box" @click="handleCloseSelect">
      <btc-chart
        v-if="list"
        ref="beefChart"
        :data="chartData"
        :color="chartColor"
        :y-axis-name="yAxisName"
        :is-en-expire="isEnExpire"
        @getChartX="getDateAndPrice"/>
    </div>
    <div class="item-box" style="padding: 30px;" @click="handleCloseSelect">
      <btc-table
        :type="type"
        :tableList="tableList"
        :countryList="countryList"
        :cutsList="cutsList"
        :closeSelect.sync = "closeSelect"
        :is-en-expire="isEnExpire"
        @handleFeedingMode="handleFeedingMode"
        @handleCountryItems="handleCountryItems"
        @handleCuts="handleCuts"/>
    </div>
  </div>
</template>

<script>
import BtcTable from './components/table/beef'
import BtcChart from './components/chart'
import BtcNavBar from './components/navbar'
import { getSeries } from '@/views/cn/btcprice/utils'
import commonmix from '@/utils/commonmix'
import { getLocalStore } from '@/utils/common'

export default {
  mixins: [commonmix],
  name: 'CnExportsToChina',
  components: {
    BtcTable,
    BtcChart,
    BtcNavBar
  },
  data () {
    return {
      countryItems: ['巴西'],
      types: [
        { label: '现货', value: '_spotgoods' },
        { label: '期货', value: '_futures' }
      ],
      listQuery: {
        UserID: getLocalStore().UserID,
        Part: '牛腩80vl',
        Country: '巴西',
        Parts: ['牛腩80vl'],
        Countries: ['巴西'],
        RecommendDay: 0,
        FeedingMode: '草饲',
        Type: '_spotgoods',
        IsFirst: true,
        IsEn: false
      },
      dateListQuery: {
        ReportType: '_spotgoods',
        LanguageCode: 'zh-CN'
      },
      countryQuery: {
        BeefPriceKeyType: '_origin',
        BeefPriceKeySecondParent: '_spot',
        FeedingMode: '草饲'
      },
      cutsQuery: {
        UserID: getLocalStore().UserID,
        ReportID: 867,
        PreReportID: 862,
        OriginPlace: '全部',
        Part: '全部',
        FeedingMode: '草饲'
      },
      type: { label: '现货', value: '_spotgoods' },
      chartColor: { Color1: 'rgba(7,155,188)', Color2: 'rgba(231,236,138)' },
      chartData: {},
      countryList: null,
      cutsList: null,
      list: null,
      tableList: null,
      dateList: null,
      yAxisName: '元/公斤',
      closeSelect: false
    }
  },
  mounted () {
    this.getDateData()
    this.getCountry()
  },
  computed: {
    isEnExpire () {
      return this.getUserProfile(this.$t('message._beefpriceindex'))
    }
  },
  methods: {
    // 获取到查询的时间
    getDateData () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetWebPriceVaildDate', this.dateListQuery).then(() => {
        this.dateList = this.$store.getters.priceDate
        this.$store.dispatch('SetLoadingStatus', false)
        this.getData()
        this.handleDate(this.dateList[0].ReportDateTime)
        this.getCuts()
      }).catch((err) => {
        console.error(err)
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    getData () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetWebPriceDataIndex', this.listQuery).then(() => {
        this.list = this.$store.getters.priceRow
        this.riseList = this.list.riseList
        this.chartData = { categories: this.list.categories, series: getSeries(this.list.series) }
        if (this.$refs.beefChart) {
          this.$refs.beefChart.refreshData({ categories: this.list.categories, series: getSeries(this.list.series, this.listQuery.Parts.length >= this.listQuery.Countries.length) })
        }
        this.getDateAndPrice(this.dateList[0].ReportDateTime)
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        console.error(err)
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    getCountry () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetWebFrontPriceDictionary', this.countryQuery).then(() => {
        this.countryList = this.$store.getters.priceCountry.map(v => {
          // return v.BeefPriceValCn
          return v.BeefPriceValCn
        })
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        console.error(err)
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    getCuts () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetWebPriceDataList', this.cutsQuery).then(() => {
        this.cutsList = this.getCutsList(this.$store.getters.priceCuts)
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        console.error(err)
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    getDateAndPrice (category) {
      const rise = []
      for (let i = 0; i < this.list.series.length; i++) {
        const item = this.list.series[i]
        if (item.data && item.data.length > 0) {
          for (let i = 0; i < this.riseList.length; i++) {
            const end = this.riseList[i][0].key.indexOf('-')
            const key = this.riseList[i][0].key.substring(0, end)
            if (key === item.name) {
              const riseFilterList = this.riseList[i].filter(v => {
                return v.key === item.name + '-' + category
              })
              if (riseFilterList.length) {
                rise.push(riseFilterList.map(v => {
                  if (this.listQuery.Parts.length >= this.listQuery.Countries.length) {
                    return { country: this.listQuery.Countries[0], cuts: item.name, date: category, money: item.data[this.list.categories.indexOf(category)], rise: v.rise }
                  } else if (this.listQuery.Parts.length < this.listQuery.Countries.length) {
                    return { country: item.name, cuts: this.listQuery.Parts[0], date: category, money: item.data[this.list.categories.indexOf(category)], rise: v.rise }
                  }
                })[0])
              } else {
                rise.push({ country: item.name, cuts: this.listQuery.Parts[0], date: category, money: '--', rise: '--' })
              }
            }
          }
        }
      }
      this.tableList = rise
    },
    getCutsList (data) {
      const cuts = []
      this.countryItems.forEach(item => {
        if (item === '爱尔兰') {
          cuts.push(['板腱', '牛霖', '大米龙', '保乐肩', '脖肉'])
        } else {
          cuts.push(data.filter(v => {
            return item === v.OriginPlace
          }).map(v => {
            return v.Part
          }))
        }
      })
      return cuts.reduce((a, b) => a.filter(c => b.includes(c)))
    },
    handleCountryItems (item) {
      this.countryItems = item
      this.cutsList = this.getCutsList(this.$store.getters.priceCuts)
    },
    // 切换期货现货
    handleType (item) {
      this.type = item
      this.listQuery.Type = item.value
      this.listQuery.FeedingMode = '草饲'
      this.listQuery.Countries = ['巴西']
      this.listQuery.Parts = ['牛腩80vl']
      this.listQuery.Part = '牛腩80vl'
      this.listQuery.Country = '巴西'

      this.countryQuery.BeefPriceKeySecondParent = item.value === '_spotgoods' ? '_spot' : '_futures'
      this.countryQuery.FeedingMode = '草饲'

      this.cutsQuery.FeedingMode = item.value === '_spotgoods' ? '草饲' : ''

      this.countryItems = ['巴西']

      this.yAxisName = item.value === '_spotgoods' ? '元/公斤' : '美元/吨'

      if (item.value === '_spotgoods') {
        this.chartColor = { Color1: 'rgba(7,155,188)', Color2: 'rgba(231,236,138)' }
      } else {
        this.chartColor = { Color1: 'rgba(209,0,0)', Color2: '#FFFFFF' }
      }

      this.dateListQuery.ReportType = item.value

      this.getCountry()
      this.getDateData()
    },
    // 切换草谷饲
    handleFeedingMode (item) {
      this.listQuery.FeedingMode = item.FeedingMode
      this.listQuery.Parts = item.Parts
      this.listQuery.Part = item.Parts[0]
      this.listQuery.Countries = item.Countries
      this.listQuery.Country = item.Countries[0]

      this.countryQuery.FeedingMode = item.FeedingMode === '草饲' ? '草饲' : '谷饲'

      this.cutsQuery.FeedingMode = item.FeedingMode
      this.countryItems = item.FeedingMode === '草饲' ? ['巴西'] : ['澳大利亚']

      this.chartColor = item.FeedingMode === '草饲' ? { Color1: 'rgba(7,155,188)', Color2: 'rgba(231,236,138)' } : { Color1: 'rgba(224,125,0)', Color2: 'rgba(224,128,83)' }

      this.getCountry()
      this.getCuts()
      this.getData()
    },
    // 筛选
    handleCuts (item) {
      this.listQuery.Parts = item.Parts
      this.listQuery.Part = item.Parts[0]
      this.listQuery.Countries = item.Countries
      this.listQuery.Country = item.Countries[0]
      this.getData()
    },
    handleDate (item) {
      this.dateList.filter((v, index) => {
        if (v.ReportDateTime === item) {
          const index1 = index + 1
          this.cutsQuery.ReportID = this.dateList[index].ReportID
          this.cutsQuery.PreReportID = this.dateList[index1].ReportID
        }
      })
      this.getData()
    },
    handleCloseSelect () {
      this.closeSelect = true
    }
  }
}
</script>

<style lang="scss" scoped>
.item-box {
  min-height: 420px;
  margin: 20px;
  padding-top: 20px;
  border-radius: 20px;
  background-color: #ffffff;
}

// 筛选
.selector-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selector-left {
  display: flex;
  justify-content: space-between;
}
</style>
